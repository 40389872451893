import React, { Component } from 'react';
import { Row } from 'antd';
import Label from '../Label';

export class Terms extends Component {
  render() {
    return (
      <React.Fragment>
        <Row style={{ paddingBottom: '8px', paddingRight:'40px' }}>
          <Label caption='PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THIS MOBILE APP AND WEBSITE.' labelType='H6' block={true} />
        </Row>
        <Row style={{ marginBottom: '48px', overflowY:'scroll', maxHeight:'500px', paddingTop:'16px',borderTop:'thin dotted darkgrey' }}>
          <Label caption='The following Terms and Conditions govern and apply to your use of or reliance upon this website and mobile app maintained by Langley Pos-Abilities Society (the “Website”). Your access or use of the Website indicates that you have read, understood, and agree to be bound by these Terms and Conditions and any other applicable laws, statutes and/or regulations. We may change these Terms and Conditions at any time without notice, effective upon its posting to the Website. Your continued use of the Website will be considered your acceptance of the revised Terms and Conditions.' block={true} />
          <Label caption='1. INTELLECTUAL PROPERTY' labelType='Subtitle1' block={true} />
          <Label caption='All intellectual property on the Website is owned by us or our licensors, which includes materials protected by copyright, trademark, or patent laws. All trademarks, service marks and trade names are owned, registered and/or licensed by us. All content on the Website, including but not limited to text, software, code, designs, graphics, photos, sounds, music, videos, applications, interactive features, and all other content is a collective work under Canadian and other copyright laws and is the proprietary property of Langley Pos-Abilities Society: All rights reserved. ' block={true} />
          <Label caption='2. USE OF SOCIETY MATERIALS' labelType='Subtitle1' block={true} />
          <Label caption='Please note that the information provided on this website may have been provided by a third party and as such we cannot take responsibility for false or misleading information. In addition, the information provided may not be of use to all people with disabilities and therefore we encourage you to take care with which information you use. We may provide you with certain information because of your use of the Website including, but not limited to, documentation, data, or information developed by us, and other materials which may assist in the use of the Website or Services (“Society Materials”). The Society Materials may not be used for any other purpose than the use of this Website and the services offered on the Website. Nothing in these Terms of Use may be interpreted as granting any license of intellectual property rights to you.' block={true} />
          <Label caption='3. ACCEPTABLE USE' labelType='Subtitle1' block={true} />
          <Label caption='You are not to use the Website for any unlawful purpose. You agree not to use the Website in any way that could damage the Website, the services, or the general business of www.pushforaccessibility.pos-abilities.org.' block={true} />
          <Label caption='4. PROTECTION OF PRIVACY' labelType='Subtitle1' block={true} />
          <Label caption="Through your use of the Website, you may provide us with certain information. By using the Website, you authorize us to use your information in Canada and any other country where we may operate. We may receive information from external applications you use to access our website, or we may receive information through various technologies, such as cookies, log files, clear gifs, web beacons or others. We use the information gathered from you to ensure your continued valuable experience on our website. We may also track some of the passive information received to improve our marketing and analytics, and for this, we may work with third party providers, including other marketers. If you would like to disable our access to any passive information we receive from the use of various technologies, you may choose to disable cookies in your web browser.  " block={true} />
          <Label caption='5. REVERSE ENGINEERING AND SECURITY' labelType='Subtitle1' block={true} />
          <Label caption="You may not undertake any of the following actions: a) Reverse engineer or attempt to reverse engineer or disassemble any code or software from or on the Website. b) Violate the security of the Website through any unauthorized access, circumvention of encryption or other security tools, data mining or interference to any host, user, or network. " block={true} />
          <Label caption='6. INDEMNIFICATION' labelType='Subtitle1' block={true} />
          <Label caption="You defend and indemnify Langley Pos-Abilities Society and any of its affiliates and hold us harmless against any, and all legal claims and demands, including reasonable lawyer’s fees, which may arise from or relate to your use or misuse of the Website, your breach of these Terms and Conditions, or your conduct or actions. We will select our own legal counsel and may participate in our own defense if we wish to." block={true} />
          <Label caption='7. SERVICE INTERRUPTIONS' labelType='Subtitle1' block={true} />
          <Label caption="We may need to interrupt your access to the Website to perform maintenance or emergency services on a scheduled or unscheduled basis. You agree that your access to the Website may be affected by unanticipated or unscheduled downtime, for any reason, but that we will have no liability for any damage or loss caused because of such downtime." block={true} />
          <Label caption='8. NO WARRANTIES' labelType='Subtitle1' block={true} />
          <Label caption="Your use of the Website is at your sole and exclusive risk and any services provided by us are on an “as is” basis. We refuse any, and all express or implied warranties of any kind, including, but not limited to the implied warranty of fitness for a particular purpose and the implied warranty of merchantability. We make no warranties that the Website will meet your needs or that the Website will be uninterrupted, errorfree, or secure. We also make no warranties as to the reliability or accuracy of any information on the Website or obtained through the Services. Any damage that may occur to you, through your computer system, or because of loss of your data from the use of the Website is your sole responsibility and we are not liable for any such damage or loss. " block={true} />
          <Label caption='9. PRIVACY' labelType='Subtitle1' block={true} />
          <Label caption="Internet communications are subject to interception, loss, or alteration and, as consequences, you acknowledge that information or data you provide by electronic means by accessing or using this Website are not confidential or exclusive, except to the extent required by the applicable laws, and that communications by email may be intercepted, altered, or lost. For more information, please refer to our Privacy Policy, available on the Website. " block={true} />
     
        </Row>
   </React.Fragment>
    )
  }
}
export default Terms