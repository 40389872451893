import React, { Component } from 'react';
import classes from './MobileLayout.css';
import MobileTopBar from '../Navigation/MobileTopBar';

class MobileLayout extends Component {
      
    state = {
        currentIndex: parseInt(this.props.mobileMenuIndex),
        windowHeight: window.innerHeight,
        imageNumber: null,
    };
    

    componentDidMount() {
        let imageNumber = Math.floor(Math.random() * 3);
        this.setState({ currentIndex: parseInt(this.props.mobileMenuIndex), });
        this.setState({ windowHeight: window.innerHeight, imageNumber: imageNumber, });
        window.addEventListener('resize', this.updateDimensions);
    }


    componentDidUpdate(prevProps) {
        const { currentIndex } = this.state;
        const mobileMenuIndex = this.props.mobileMenuIndex;

        if (parseInt(mobileMenuIndex) !== currentIndex && mobileMenuIndex !== undefined && mobileMenuIndex !== null)
            this.setState({ currentIndex: parseInt(mobileMenuIndex), });
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions = () => {
        this.setState({ windowHeight: window.innerHeight, });
    };

    render() {
         
        const { windowHeight } = this.state;
     
        return (
            <div className={classes.mobileLayout} style={{ height: windowHeight }}>
                <div className={classes.headerStyle}> 
                <MobileTopBar 
                    mainMenuKey={this.props.mainMenuKey} 
                    mainMenuName={this.props.mainMenuName}
                    onToolbarClick={this.props.onMobileToolbarClick}
                />
                </div>
               
                <div style={{ 
                    position: 'absolute',
                    left: '0px',
                    right: '0px',
                    top: '56px',
                    overflow: 'auto',
                    zIndex: 1
                 }}> 
                    {this.props.children}
                </div>
            </div>
        )
    };
}


export default MobileLayout;