import React, { Component } from 'react';
import { Row } from 'antd';
import Label from '../Label';

export class AboutUs extends Component {
  render() {
    return (
      <React.Fragment>
        <Row style={{ paddingBottom: '8px' }}>
          <Label caption='About Us' labelType='H6' block={true} />
        </Row>
        <Row style={{ marginBottom: '48px', overflowY: 'scroll', maxHeight: '500px', paddingTop: '16px', borderTop: 'thin dotted darkgrey' }}>
          <Label caption='Langley Pos-Abilities Society (LPAS) is a not-for-profit, volunteer supported agency that helps improve the quality of life for people with disabilities, including those living with mobility issues, hearing loss, blindness, plus many other conditions requiring assistive devices.' labelType='Body2' block={true} style={{marginBottom: '16px'}} />
          <Label caption='Langley Pos-Abilities Society started in 2010, and stands for an IDEA: Inclusion, Diversity, Equality, and Accessibility. We assist our clients by giving a hand-up, not a hand-out.' labelType='Body2' block={true} />
          <Label caption='The brainchild of LPAS is Zosia Ettenberg, a former physiotherapist and former Director of Education and Quality Assurance at Langley Memorial Hospital in BC., who is now in a wheelchair herself. Our goal is to facilitate the donation and refurbishment of personal assistance devices which are then donated to individuals in need in the communities we serve.' labelType='Body2' block={true} style={{ marginBottom: '16px' }} />
          <Label caption='In addition, we aim to create awareness, and educate the public on some of the daily difficulties people with disabilities have through our TOAD (Try On A Disability) Program. We have used our TOAD obstacle course for demonstrations at schools and workplaces. Zosia is also deeply involved in advocating for the disabled in our communities.' labelType='Body2' block={true} style={{ marginBottom: '16px' }} />
          <Label caption='We have also, of course, more recently developed an map application, with the help and guidance of Better World Software Inc., to assist people with disabilities identify places which meet certain standards of access and ease of maneuverability. This map application will allow users to identify places that are user/accessibility friendly, and also permits users to upload their own reports.' labelType='Body2' block={true} style={{ marginBottom: '16px' }} />
        </Row>
      </React.Fragment>
    )
  }
}
export default AboutUs