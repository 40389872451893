const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export function register(config) {
//process.env.NODE_ENV === 'production' && 
  if ('serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }
  
    window.addEventListener('load', () => {

      const swUrl = `${process.env.PUBLIC_URL}/webmap-service-worker.js`;

      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
          console.log('local Host Loading');
          checkValidServiceWorker(swUrl, config);
      } else {
        // Is not localhost. Just register service worker
        registerValidSW(swUrl, config);
       
      }
    }, { passive: true });
  }
}






function registerValidSW(swUrl, config) {
    console.log('registerValidSW');
  
    navigator.serviceWorker
    .register(swUrl)
    .then(function(registration){
     
      if (registration.installing == null) {
        return;
      }

      if(registration.installing.state ==="redundant")
      {
        console.log('service worker is being replaced');
       }

      registration.onupdatefound = () => {

        const installingWorker = registration.installing;
      
        // if (installingWorker == null) {
        //   return;
        // }

         installingWorker.onstatechange = () => {
      
          if (installingWorker.state === 'installed') {
             if (navigator.serviceWorker.controller) {
              // At this point, the updated precached content has been fetched,
              // but the previous service worker will still serve the older
              // content until all client tabs are closed.
                console.log('New content is available and will be used when all tabs');      
               // Execute callback
               if (config && config.onUpdate) {
                 config.onUpdate(registration);
                 console.log('call back');

               }
              } 
              
              else {
                // At this point, everything has been precached.
                // It's the perfect time to display a
                // "Content is cached for offline use." message.
                console.log('Content is cached for offline use.');
              
                // Execute callback
                if (config && config.onSuccess) {
                  config.onSuccess(registration);
                }
              }
          }
          else
          {

            console.log('INSTALLINGWORKER.STATE',installingWorker.state);

          }
        };
      };

   


    })
    .catch(error => {
      console.error('Error during service worker registration:', error);
    });




  //  Notification.requestPermission().then(function (permission) { console.log('Notification permission:', permission) });
  
}

function checkValidServiceWorker(swUrl, config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl)
    .then(response => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then(registration => {
          registration.unregister().then(() => {
            window.location.reload();
          
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config);
      
      }
    })
    .catch(() => {
      console.log(
        'No internet connection found. App is running in offline mode.'
      );
    });
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister();
    });
  }
}


